import type { UseToastOptions } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

const defaultOptions: UseToastOptions = {
  position: 'top-right',
  isClosable: true,
}

export const useCustomToast = (options: UseToastOptions = {}) => {
  const toast = useToast({ ...defaultOptions, ...options })
  return { toast }
}
